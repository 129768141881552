import HeaderSection from 'sections/Header';
import HeroSection from 'sections/Hero';
import FeaturesSection from 'sections/Features';
import PrototypeSection from 'sections/Prototype';
import FooterSection from 'sections/Footer';

const App = () => {
    return (
        <div>
            <HeaderSection />
            <HeroSection />
            {/* <BlankGapSection /> */}
            <FeaturesSection />
            {/* <BlankGapSection /> */}
            <PrototypeSection />
            <FooterSection />
        </div>
    );
};

export default App;
