import { Hero, Image } from 'ux4';

import { TextGenerateEffect } from "components/ui/text-generate-effect";

import imgHero from 'assets/images/hero.svg';

const words = `Enable learners to continuously learn unmastered knowledge, tailored to their pace and preferred style.`;

const HeroSection = () => {
    return (
        <Hero className={'bg-wisdom min-h-[640px]'} contentClassName={'flex-col-reverse px-4'}>
            <div>
                <h1 className="text-5xl text-white font-bold">Wisdom Journey</h1>
                <div className="py-6 text-3xl text-white">AI-Empowered Education Technology</div>
                <div className="py-2 text-2xl text-journey">
                    <TextGenerateEffect words={words} />
                </div>
            </div>
            <Image src={imgHero} className="w-4/5 md:w-2/5" />
        </Hero>
    );
};

export default HeroSection;
