import { cn } from 'utils/styling';

const Hero = ({ className, children, contentClassName, ...props }) => {
    return (
        <div className={cn("hero min-h-screen bg-base-200", className)}>
            <div className={cn("hero-content flex-col md:items-center lg:flex-row", contentClassName)}>
                {children}
            </div>
        </div>
    );
};

export default Hero;
