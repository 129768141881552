import { Hero, Image } from 'ux4';
import imgFeature from 'assets/images/feature.png';
import imgFeature1 from 'assets/images/feature-1.png';
import imgFeature2 from 'assets/images/feature-2.png';
import imgFeature3 from 'assets/images/feature-3.png';
import imgFeature4 from 'assets/images/feature-4.png';
import imgFeature5 from 'assets/images/feature-5.png';

import { TextGenerateEffect } from 'components/ui/text-generate-effect';

const FeaturesSection = () => {
    return (
        <div id="features" className="bg-white pt-16 pb-8">
            <h2 className="text-4xl text-wisdom font-bold text-center">Core Features</h2>
            <Hero
                className={'bg-background min-h-[640px]'}
                contentClassName={'flex-col-reverse md:gap-16 lg:gap-24 lg:flex-row-reverse'}>
                <div>
                    <ul role="list" className="w-full py-5 divide-y divide-journey">
                        <li className="flex justify-start py-3">
                            <div className="flex min-w-0 gap-x-4 items-center">
                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgFeature1} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-2xl font-semibold leading-6 text-wisdom">Adaptive Learning</p>
                                    <p className="text-wrap mt-3 font-serif text-1xl leading-5 text-wisdom">
                                        Leverages individual's unique skill graph to create a customizedearning pathway,
                                        addressing gaps and building on strengths.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="flex justify-start py-3">
                            <div className="flex min-w-0 gap-x-4 items-center">
                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgFeature2} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-2xl font-semibold leading-6 text-wisdom">
                                        Instant Sstreaming Learning
                                    </p>
                                    <p className="text-wrap mt-3 font-serif text-1xl leading-5 text-wisdom">
                                        Fragmented,on-demand learning content streaming, personalized foluser's time and
                                        preferences, akin to short video browsing.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="flex justify-start py-3">
                            <div className="flex min-w-0 gap-x-4 items-center">
                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgFeature3} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-2xl font-semibold leading-6 text-wisdom">Virtual Tutoring</p>
                                    <p className="text-wrap mt-3 font-serif text-1xl leading-5 text-wisdom">
                                        Personalized Al virtual tutors providing real-time, accurateexplanations, and
                                        interactive learning support.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="flex justify-start py-3">
                            <div className="flex min-w-0 gap-x-4 items-center">
                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgFeature4} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-2xl font-semibold leading-6 text-wisdom">Immersive Learninc</p>
                                    <p className="text-wrap mt-3 font-serif text-1xl leading-5 text-wisdom">
                                        Leverages individual's unique skill graph to create a customizedearning pathway,
                                        addressing gaps and building on strengths.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="flex justify-start py-3">
                            <div className="flex min-w-0 gap-x-4 items-center">
                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgFeature5} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-2xl font-semibold leading-6 text-wisdom">Adaptive Learning</p>
                                    <p className="text-wrap mt-3 font-serif text-1xl leading-5 text-wisdom">
                                        Deep exploration through Al-created virtual scenarios or gamifiednteractive
                                        content focused on specific educational topics.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="flex justify-start py-3">
                            <div className="flex min-w-0 gap-x-4 items-center">
                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={imgFeature2} alt="" />
                                <div className="min-w-0 flex-auto">
                                    <p className="text-2xl font-semibold leading-6 text-wisdom">
                                        Personalized Cognitive Inference
                                    </p>
                                    <p className="text-wrap mt-3 font-serif text-1xl leading-5 text-wisdom">
                                        Al-powered tool to create a personal knowledge base, enablingeasy retrieval and
                                        organization ofinformation.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <Image src={imgFeature} className="w-4/5 md:w-3/5 lg:w-2/5" />
            </Hero>
        </div>
    );
};

export default FeaturesSection;
