import { Hero, Image } from 'ux4';

import { TypewriterEffectSmooth } from 'components/ui/typewriter-effect';

import imgPrototype from 'assets/images/prototype.png';
import imgLogoLg from 'assets/images/logo-lg.png';

const words = [
    {
        text: 'Coming',
        className: 'text-xl lg:text-3xl text-white drop-shadow-xl',
    },
    {
        text: 'This',
        className: 'text-xl lg:text-3xl text-white drop-shadow-xl',
    },
    {
        text: 'May:',
        className: 'text-xl lg:text-3xl text-white drop-shadow-xl',
    },
    {
        text: 'Experience',
        className: 'text-xl lg:text-3xl text-accent drop-shadow-xl',
    },
    {
        text: 'the',
        className: 'text-xl lg:text-3xl text-accent drop-shadow-xl',
    },
    {
        text: 'Different',
        className: 'text-xl lg:text-3xl text-accent drop-shadow-xl',
    },
];

const PrototypeSection = () => {
    return (
        <div id="wisley" className="bg-wisdom pt-16 pb-8">
            <h2 className="text-4xl text-white font-bold text-center">Wisley.AI Learning App</h2>

            <Hero className={'bg-wisdom min-h-10 lg:min-h-[670px]'} contentClassName={'px-4'}>
                <div className="grid justify-items-center">
                    <div className="card py-3 w-4/5">
                        <Image src={imgPrototype} className="rounded-box" />
                        <div className="absolute top-0 left-0 w-full h-full flex justify-center">
                            <div className="my-auto text-center">
                                <div className="avatar">
                                    <div className="w-32 drop-shadow-lg rounded-xl">
                                        <img src={imgLogoLg} />
                                    </div>
                                </div>
                                <div className="bg-grey-tint pl-2 my-2">
                                    <TypewriterEffectSmooth words={words} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Hero>
        </div>
    );
};

export default PrototypeSection;
