import { cn } from 'utils/styling';

const Header = ({ sticky = true, className, $slotStart, children, $slotEnd }) => {
    return (
        <header
            className={cn(
                sticky ? 'sticky' : '',
                'top-0 z-50 flex min-h-16 items-center gap-4 border-b bg-background p-4 md:px-6',
                className,
            )}
            >
            <div className="container mx-auto">
                <nav className="w-full flex-col gap-6 text-lg font-medium md:justify-between md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
                    {$slotStart}
                    {children}
                    {$slotEnd}
                </nav>
            </div>
        </header>
    );
};

export default Header;
